import React from 'react';
import './textarea.scss';
import PropTypes from 'prop-types';

const Textarea = (props) => {
  return (
    <div className="m-input--group">
      <label className={`m-input-textarea--label`} htmlFor={props.name}>
        {props.label}
      </label>
      <textarea
        className={`m-input-textarea`}
        name={props.name}
        id={props.name}
        placeholder={props.placeholder}
        value={props.value}
      />
    </div>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Textarea;
