import React from 'react';
import classNames from 'classnames';
import './form.scss';

const Form = (props) => {
  const classnames = classNames(`m-form`, {
    // size
    'm-form-middle': props.middle,
  });
  return (
    <form id={props.id} method={props.method} action={props.action} className={classnames}>
      {props.children}
    </form>
  );
};

export default Form;
