import React, { Component } from 'react';
import Layout from '../components/structures/Layout/layout.js';
import Section from '../components/structures/Section/section';
import Wrapper from '../components/structures/Wrapper/wrapper';
import Form from '../components/modules/Form/Form';
import Input from '../components/modules/Form/Input/input';
import Radio from '../components/modules/Form/Radio/radio';
import Textarea from '../components/modules/Form/Textarea/textarea';
import Checkbox from '../components/modules/Form/Checkbox/checkbox';
import Select from '../components/modules/Form/Select/select';

class TestContact extends Component {
  render() {
    const radioItems = [
      { name: 'has_order', label: 'Yes', id: 'subject-1' },
      { name: 'has_order', label: 'No', id: 'subject-2' },
    ];
    const checkboxItems = [
      {
        name: 'accept_policy',
        label: 'I have read and understood the Privacy Policy.',
        id: 'accept_policy',
      },
    ];
    const selectItems = [
      { option: 'Assistancewithmyform', label: 'Assistance with my form' },
      { option: 'FormNotReceived', label: 'Form Not Received' },
      { option: 'ApplicationIssues', label: 'Application Issues' },
      { option: 'PaymentIssues', label: 'Payment Issues' },
      { option: 'RefundRequest', label: 'Refund Request' },
      { option: 'Complaint', label: 'Complaint' },
    ];
    return (
      <Layout nav>
        <Section>
          <Wrapper>
            <Form id={`createaccount`} action={`/contact`}>
              <Input
                label={`Name and Surname`}
                placeholder={`Name and Surname`}
                type={`text`}
                name={`name`}
                id={`name`}
              />
              <Input
                label={`Email`}
                placeholder={`Email`}
                type={`email`}
                name={`email`}
                id={`email`}
              />
              <Radio
                label={`Have you already placed an order?`}
                name={`order`}
                items={radioItems}
              />
              <Select label={`Subject`} name={`subject`} id={`subject`} items={selectItems} />
              <Textarea
                label="Your Message"
                name="message"
                id="message"
                placeholder="Introduce your message"
              />
              <Checkbox name={`order`} items={checkboxItems} />
            </Form>
          </Wrapper>
        </Section>
      </Layout>
    );
  }
}

export default TestContact;
