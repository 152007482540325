import React from 'react';
import './checkbox.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Checkbox = (props) => {
  const classnames = classNames('m-input-checkbox', {
    'm-input-checkbox--primary': props.primary,
  });

  return (
    <div className={`m-input--group`}>
      {props.label && (
        <label className={`m-input-checkbox--label`} htmlFor={props.name}>
          {props.label}
        </label>
      )}
      {props.items.map((checkboxItem, key) => {
        return (
          <div key={key}>
            <label className={`m-input-checkbox-item--label`} key={checkboxItem.id}>
              <input
                className={props.className ? props.className + ` ` + classnames : classnames}
                type="checkbox"
                id={`${props.name}_${checkboxItem.id}`}
                name={props.name}
              />
              <span dangerouslySetInnerHTML={{ __html: checkboxItem.label }} />
            </label>
          </div>
        );
      })}
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default Checkbox;
