import React from 'react';
import './input.scss';
import classNames from 'classnames';

const Input = (props) => {
  const classnames = classNames('m-input', {
    'm-input--primary': props.primary,
    'm-input--button': props.type && props.type === 'button',
  });

  return (
    <div className={`m-input--group`}>
      <label className={`m-input--label`} htmlFor={props.name}>
        {props.label}
      </label>
      <input
        className={props.className ? props.className + ` ` + classnames : classnames}
        type={props.type}
        name={props.name}
        id={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onClick={props.onClick}
      />
    </div>
  );
};

export default Input;
